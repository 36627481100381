import { gql, request } from "graphql-request"
import { AudiobookAttributes } from "graphql/audiobookAttributes"
import { PodcastAttributes } from "graphql/podcastAttributes"
import { StationAttributes } from "graphql/stationAttributes"
import { useQuery } from "react-query"

// Query name
export const contentItemsQueryName = "contentItemsQuery"

// GraphQL query
const query = gql`
  ${AudiobookAttributes()}
  ${PodcastAttributes()}
  ${StationAttributes()}

  query ContentItems($storeCountry: String!, $paths: [ID]!) {
    selection(storeCountry: $storeCountry, sort: false, paths: $paths) {
      ... on Audiobook {
        ...AudiobookAttributes
      }
      ... on Podcast {
        ...PodcastAttributes
      }
      ... on Station {
        ...StationAttributes
      }
    }
  }
`

//
// Query for the metadata of a set of content item references
//
export const contentItemsQuery = async ({ queryKey }) => {
  const [, { storeCountry, contentRefs }] = queryKey
  try {
    // Run GraphQL query to get content items
    const contentItems = await request(
      process.env.GATSBY_GRAPHQL_CONTENT_ENDPOINT,
      query,
      {
        storeCountry: storeCountry,
        paths: contentRefs,
      }
    )
    return contentItems?.selection
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use the content items query
//
export const useContentItemsQuery = (
  storeCountry,
  contentRefs,
  keepPreviousData = false
) => {
  return useQuery(
    [contentItemsQueryName, { storeCountry, contentRefs }],
    contentItemsQuery,
    {
      staleTime: Infinity,
      keepPreviousData: keepPreviousData,
      enabled:
        storeCountry !== undefined &&
        contentRefs !== undefined &&
        contentRefs.length > 0,
    }
  )
}
