import ContentCard from "components/contentCard/contentCard"
import Button from "components/controls/button"
import Spinner from "components/utils/spinner"
import { ContentItemsProvider } from "context/contentItemsContext"
import { useSearchQueryContext } from "context/searchQueryContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import GridLayout from "layouts/gridLayout"
import PropTypes from "prop-types"
import { default as React, useMemo } from "react"
import { useInfiniteHits } from "react-instantsearch-hooks-web"
import { useContentItemsQuery } from "reactQuery/queries/contentItemsQuery"

/**
 * Display hits from the global search bar
 */
const SearchHits = ({ storeCountry }) => {
  // Algolia search infinite hits hook
  // See: https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react-hooks/#hook-api-showmore
  const {
    hits,
    results: searchResults,
    isLastPage,
    showMore,
  } = useInfiniteHits()

  // The current global search query
  const { query } = useSearchQueryContext()

  /**
   * Derive the content refs to query from
   * the hits that result from an Algolia search.
   */
  const contentRefs = useMemo(() => {
    if (query === undefined) {
      return
    }
    return hits.map((hit) => {
      return hit.path
    })
  }, [hits, query])

  //
  // Query content items based on content references from search hits
  //
  // NOTE: This keeps the data from the previous query so that the data
  //       remains in the cache when the user presses the "More results"
  //       button.
  //
  const {
    data: contentItems,
    isStale,
    // error,
    isFetching,
  } = useContentItemsQuery(storeCountry, contentRefs, true)

  // Build a map of audiobook content items
  const contentItemsMap = useContentItemsMap(contentItems)

  //
  // Determine whether or not to display the loading spinner
  //
  const displayLoadingSpinner = useMemo(() => {
    // Don't show spinner past first page, as
    // we use the "More results" button to show
    // loading progress instead.
    if (searchResults?.page > 0) {
      return false
    }
    return isFetching
  }, [isFetching, searchResults?.page])

  //
  // Determine whether or not to display search results
  //
  const displayResults = useMemo(() => {
    // Don't display results if they are stale
    // and we're not loading more results
    if (isStale && searchResults?.page === 0) {
      return false
    }

    // Otherwise, show the results!
    return true
  }, [isStale, searchResults?.page])

  //
  // Determine whether or not to display the "More results" button
  //
  const displayMoreResultsButton = useMemo(() => {
    // Don't display the button if content is stale
    // and we're not loading more results
    if (isStale && searchResults?.page === 0) {
      return false
    }
    // Show a button if we have content items
    return contentItems
  }, [contentItems, isStale, searchResults?.page])

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={false}
      className="col-span-full"
    >
      <ContentItemsProvider contentItemsMap={contentItemsMap}>
        {displayResults &&
          contentItems?.map((contentItem) => {
            return (
              <ContentCard key={contentItem.ref} contentRef={contentItem.ref} />
            )
          })}
      </ContentItemsProvider>

      {/* "More results" button */}
      <div className="col-span-full flex justify-center">
        {displayMoreResultsButton && (
          <Button
            text="More results"
            shadowHeight={1}
            size="sm"
            loading={isFetching}
            disabled={isLastPage}
            onClick={showMore}
            className="w-52 mt-5 transition-opacity duration-500 ease-in-out"
          />
        )}
      </div>

      {/* Initial loading spinner */}
      <Spinner isLoading={displayLoadingSpinner} className="absolute top-24" />
    </GridLayout>
  )
}

SearchHits.propTypes = {
  storeCountry: PropTypes.string.isRequired,
}

export default SearchHits
SearchHits.whyDidYouRender = true
