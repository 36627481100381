import produce from "immer"
import React, { createContext, useContext, useReducer } from "react"

/**
 * A context provider that captures the current global search query.
 */
export const SearchQueryContext = createContext()
export const SearchQueryDispatchContext = createContext()

const initialState = {
  query: undefined,
}

export const QUERY = "query"

const reducer = (state, action) => {
  switch (action.type) {
    case QUERY:
      return produce(state, (draftState) => {
        draftState.query = action.payload
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const SearchQueryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <SearchQueryContext.Provider value={state}>
      <SearchQueryDispatchContext.Provider value={dispatch}>
        {children}
      </SearchQueryDispatchContext.Provider>
    </SearchQueryContext.Provider>
  )
}

export const useSearchQueryContext = () => {
  const context = useContext(SearchQueryContext)
  if (context === undefined) {
    throw new Error(
      "useSearchQueryContext must be used within a SearchQueryProvider"
    )
  }
  return context
}

export const useSearchQueryDispatchContext = () => {
  const dispatchContext = useContext(SearchQueryDispatchContext)
  if (dispatchContext === undefined) {
    throw new Error(
      "useSearchQueryDispatchContext must be used within a SearchQueryProvider"
    )
  }
  return dispatchContext
}
