import React, { useEffect, useState } from "react"
import { SpinnerCircular } from "spinners-react"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"

// Load Tailwind config
const { theme } = resolveConfig(tailwindConfig)

const Spinner = ({
  isLoading = false,
  thickness = 120,
  speed = 100,
  size = 100,
  outlineColor = theme.colors.grey["DEFAULT"],
  highlightColor = theme.colors.grey.darker,
  style,
  className,
}) => {
  // Mount state of spinner
  const [isMounted, setMounted] = useState(false)

  const onTransitionEnd = () => {
    setMounted(false)
  }

  useEffect(() => {
    if (isLoading) {
      setMounted(true)
    }
  }, [isLoading])

  return (
    <div
      id="spinner"
      onTransitionEnd={onTransitionEnd}
      style={{
        opacity: isLoading ? 100 : 0,
        display: isMounted ? "flex" : "none",
        ...style,
      }}
      className={` 
        absolute
        inset-x-0
        z-10
        flex
        justify-center
        items-center
        transition-opacity duration-300 delay-75 ease-in-out
        ${className ? className : ""}
      `}
    >
      <SpinnerCircular
        speed={speed}
        thickness={thickness}
        color={highlightColor}
        secondaryColor={outlineColor}
        size={size}
      />
    </div>
  )
}

export default Spinner
