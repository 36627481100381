import Input from "components/controls/input"
import {
  QUERY,
  useSearchQueryDispatchContext,
} from "context/searchQueryContext"
import SearchIcon from "images/search-icon.svg"
import debounce from "lodash.debounce"
import PropTypes from "prop-types"
import { default as React } from "react"
import { useSearchBox } from "react-instantsearch-hooks-web"

/**
 * A global search bar, using the Algolia search indexes
 */
const SearchBar = ({ className }) => {
  // Debounce timeout before updating search term
  const DEBOUNCE_TIMEOUT = 750

  // Algolia search box hook
  const { query, refine } = useSearchBox()

  // Search query dispatch context
  const queryDispatcher = useSearchQueryDispatchContext()

  /**
   * Refine the search term using the input from the text
   * box to update the Algolia search hook.
   */
  const refineSearch = debounce((term) => {
    if (term === undefined || term === query) {
      return
    }

    // Refine the search term using the Algolia hook
    refine(term)

    // Set the search term
    queryDispatcher({
      type: QUERY,
      payload: term,
    })
  }, DEBOUNCE_TIMEOUT)

  return (
    <>
      <Input
        placeholder="Audiobooks, podcasts, radio..."
        childAlignment="left"
        isFocussed={true}
        onValueChanged={refineSearch}
        className={`
        ${className ? className : ""}
      `}
      >
        <SearchIcon className="h-4 w-4" />
      </Input>
    </>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
}

export default SearchBar
SearchBar.whyDidYouRender = true
