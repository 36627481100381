import { useLocation } from "@reach/router"
import algoliasearch from "algoliasearch/lite"
import SearchBar from "components/search/searchBar"
import SearchHits from "components/search/searchHits"
import Metadata from "components/utils/metadata/metadata"
import { StoreCountries, useCountries } from "context/countryContext"
import { Screen, useSetScreen } from "context/screenContext"
import { SearchQueryProvider } from "context/searchQueryContext"
import { useFooter } from "hooks/useFooter"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useTabBar } from "hooks/useTabBAr"
import GridLayout from "layouts/gridLayout"
import React from "react"
import { Configure, InstantSearch } from "react-instantsearch-hooks-web"

/**
 * Global search page
 */
const Search = () => {
  // Media queires to determine how many search results to request
  const { isMobile, isTablet } = useMediaQueries()

  // Country information from context
  const { storeCountry } = useCountries()

  // Create an Algolia search client
  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_CLIENT_ID,
    process.env.GATSBY_ALGOLIA_API_KEY
  )

  // Wrap the search client, to prevent requets on empty queries
  // See: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/#detecting-empty-search-requests
  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return algoliaClient.search(requests)
    },
  }

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Set the screen
  useSetScreen(Screen.SEARCH)

  // Use a footer
  useFooter(true)

  // Don't use a tab bar
  useTabBar(false)

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata title="Search" pathname={location.pathname} />

      <SearchQueryProvider>
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX}
        >
          {/* Configure the Algolia search client */}
          <Configure
            hitsPerPage={isMobile ? 10 : isTablet ? 21 : 24}
            attributesToRetrieve={["path"]}
            // availableInRegions filters audiobook titles, storeCountry filters podcasts, which is a bit of a mess ;-p
            filters={`availableInRegions:'${storeCountry}' OR storeCountry:'${storeCountry}' OR type:'station'`}

            // filters={`availableInRegions:'${StoreCountries.GB.storeCountry}' OR type:'podcast' OR type:'station'`}
            // filters={`availableInRegions:'${StoreCountries.GB.storeCountry}' AND supplier:'penguinrandomhouse-uk' OR type:'podcast' OR type:'station'`}
            // filters={`availableInRegions:'${StoreCountries.GB.storeCountry}' AND supplier:'harpercollins-uk' OR type:'podcast' OR type:'station'`}
            // filters={`availableInRegions:'${StoreCountries.GB.storeCountry}' AND supplier:'dk-uk' OR type:'podcast' OR type:'station'`}
          />
          <GridLayout
            type="fluid"
            container={false}
            offsets={false}
            className="col-span-full"
          >
            <SearchBar
              className="
                col-start1 col-span-full
                mb-8
                -ml-0.5"
            />
            <SearchHits
              storeCountry={StoreCountries.GB.storeCountry}
              className="
                col-start1 col-span-full
                mt-5"
            />
            {/* Spacer */}
            <div className="col-span-full mb-6 tablet:mb-6 laptop:mb-12" />
          </GridLayout>
        </InstantSearch>
      </SearchQueryProvider>
    </>
  )
}

export default Search
Search.whyDidYouRender = true
